import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import ConstructionSite from '../views/ConstructionSite.vue'
import ProjectFile from '@/views/ProjectFile'
import Register from '@/views/Register'
import ProjectFileContent from '@/components/project_file/ProjectFileContent'
import ProjectFileContentFaults from '@/components/project_file/ProjectFileContentFaults'
import ProjectFileContentDocuments from '@/components/project_file/ProjectFileContentDocuments'
import store from '../store'
import ProjectCanvas from '@/views/ProjectCanvas'
import Fault from '@/views/Fault'
import FaultContentRecordings from '@/components/fault/FaultContentRecordings'
import FaultContentMessages from '@/components/fault/FaultContentMessages'
import FaultContentPhotos from '@/components/fault/FaultContentPhotos'
import FaultContent from '@/components/fault/FaultContent'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    props: true,
    component: Register
  },
  {
    path: '/construction-site',
    name: 'ConstructionSite',
    component: ConstructionSite,
    beforeEnter: (to, from, next) => {
      const hasPermission = store.state.authStore.status.loggedIn
      // const user = JSON.parse(localStorage.getItem('user'))
      const user = store.state.authStore.status.user
      if (user !== null && hasPermission) {
        next()
      } else {
        next({ path: '/' })
      }
    }
  },
  {
    path: '/construction-site/:constructionSiteId',
    name: 'ProjectFile',
    component: ProjectFile,
    props: true,
    beforeEnter: (to, from, next) => {
      const hasPermission = store.state.authStore.status.loggedIn
      const user = store.state.authStore.status.user
      if (user !== null && hasPermission) {
        next()
      } else {
        next({ path: '/' })
      }
    },
    children: [
      {
        path: '',
        props: true,
        component: ProjectFileContent,
        name: 'ProjectFileContent'
      },
      {
        path: 'faults',
        props: true,
        component: ProjectFileContentFaults,
        name: 'ProjectFileContentFaults'
      },
      {
        path: 'documents',
        props: true,
        component: ProjectFileContentDocuments,
        name: 'ProjectFileContentDocuments'
      }
    ]
  },
  {
    path: '/construction-site/:constructionSiteId/project-file/:projectFileId',
    props: true,
    component: ProjectCanvas,
    name: 'ProjectCanvas'
  },
  {
    path: '/fault/:faultId',
    props: (route) => ({ faultId: route.query.faultId, constructionSiteId: route.query.constructionSiteId, projectFileId: route.query.projectFileId }),
    component: Fault,
    name: 'Fault',
    children: [
      {
        path: '',
        props: true,
        component: FaultContent,
        name: 'FaultContent'
      },
      {
        path: 'photos',
        props: true,
        component: FaultContentPhotos,
        name: 'FaultContentPhotos'
      },
      {
        path: 'recordings',
        props: true,
        component: FaultContentRecordings,
        name: 'FaultContentRecordings'
      },
      {
        path: 'messages',
        props: true,
        component: FaultContentMessages,
        name: 'FaultContentMessages'
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
