import AuthService from '../services/auth.service'
import SecureLS from 'secure-ls'
import User from '@/models/user_model'

const ls = new SecureLS({ isCompression: false })
const user = ls.get('user')

const initialState =
  user
    ? {
      status: { loggedIn: true }, user: user, userDetails: null
    }
    : {
      status: { loggedIn: false }, user: null, userDetails: null
    }

const state = initialState

const getters = {}

const actions = {
  login ({ commit }, user) {
    return AuthService.login(user).then(
      user => {
        commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('loginFailure')
        return Promise.reject(error.response.data)
      }
    )
  },
  fetchUserDetails ({ commit }) {
    return AuthService.fetchDetails().then(
      user => {
        commit('fetchUserDetailsSuccess', user)
        return Promise.resolve()
      },
      error => {
        commit('fetchUserDetailsFailure')
        return Promise.reject(error.response.data)
      }
    )
  },
  async logout ({ commit }) {
    await AuthService.logout()
    commit('logout')
  },
  register ({ commit }, user) {
    return AuthService.register(user).then(
      response => {
        commit('registerSuccess')
        return Promise.resolve(response.data)
      },
      error => {
        commit('registerFailure')
        return Promise.reject(error.response.data)
      }
    )
  }
}

const mutations = {
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  fetchUserDetailsSuccess (state, user) {
    const userDetails = new User(user)
    state.userDetails = userDetails
  },
  fetchUserDetailsFailure (state) {
    state.userDetails = null
  },
  logout (state) {
    state.status = {}
    state.user = null
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
