import axios from 'axios'
import authHeader from '@/services/auth_header'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'
import { handleRequestError } from '@/utils/util'

const API_URL = 'http://83.12.156.122:8400/construction-sites/'

class DocumentService {
  async delete (constructionSiteId, id) {
    const options = {
      method: 'delete',
      url: API_URL + constructionSiteId + '/documents/' + id,
      headers: authHeader()
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async download (constructionSiteId, id) {
    const options = {
      method: 'get',
      url: API_URL + constructionSiteId + '/documents/' + id,
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async upload (constructionSiteId, id, formData) {
    const options = {
      method: 'post',
      url: API_URL + constructionSiteId + '/documents',
      data: formData,
      headers: authUploadFile()
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}

export default new DocumentService()
