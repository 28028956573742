import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import authStore from '@/store/authStore'
import constructionSiteStore from '@/store/constructionSiteStore'
import projectFileStore from '@/store/projectFileStore'

const debug = process.env.NODE_ENV !== 'production'
const ls = new SecureLS({ isCompression: false })

export default createStore({
  modules: {
    authStore,
    constructionSiteStore,
    projectFileStore
  },
  strict: debug,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ]
})
