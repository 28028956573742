<template>
  <Navbar :items="navbarItems" screenName="constructionSite"/>

  <ConstructionSiteContent
    :constructionSites="constructionSites"
    :isLoading="isLoading"
    v-model:modelValue="searchValue"
  />
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import Navbar from '@/components/Navbar'
import ConstructionSiteContent from '@/components/construction_site/ConstructionSiteContent'
import { sleepOneSec } from '@/utils/util'
import router from '@/router'

export default {
  name: 'ConstructionSite',
  components: { ConstructionSiteContent, Navbar },
  setup () {
    const store = useStore()
    const isLoading = ref(false)
    const toast = useToast()
    const searchValue = ref('')
    const navbarItems = [
      {
        id: 1,
        label: 'Obiekty',
        to: '/'
      }
    ]

    const constructionSites = computed(() => store.state.constructionSiteStore.constructionSites?.filter(constructionSite => {
      return constructionSite.name.toLowerCase().includes(searchValue.value.toLowerCase())
    }))

    onMounted(async () => {
      isLoading.value = true
      await sleepOneSec()
      store.dispatch('constructionSiteStore/fetch').then(
        () => {
          isLoading.value = false
        },
        async (error) => {
          await store.dispatch('authStore/logout')
          await router.push('/')
          toast.add({
            severity: 'warn',
            summary: 'Wystąpił błąd',
            detail: error,
            life: 3000
          })
        }
      )
    })

    return { constructionSites, isLoading, searchValue, navbarItems }
  }
}
</script>
