import axios from 'axios'
import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { handleRequestError } from '@/utils/util'
import authUploadFile from '@/services/auth_upload_file'

const API_URL = 'http://83.12.156.122:8400/construction-sites/'

class ProjectFileService {
  async add (constructionSiteId, projectFile, formData) {
    const options = {
      method: 'post',
      url: API_URL + constructionSiteId + '/projects',
      data: {
        id: projectFile.id,
        name: projectFile.name,
        type: projectFile.type,
        extension: projectFile.extension,
        lastUpdate: projectFile.lastUpdate
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(async (response) => {
      await this._uploadImage(constructionSiteId, projectFile.id, formData)
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async delete (constructionSiteId, id) {
    const options = {
      method: 'delete',
      url: API_URL + constructionSiteId + '/projects/' + id,
      headers: authHeader()
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async update (constructionSiteId, projectFile) {
    const options = {
      method: 'put',
      url: API_URL + constructionSiteId + '/projects',
      headers: authContentTypeHeader(),
      data: {
        id: projectFile.id,
        name: projectFile.name,
        type: projectFile.type,
        extension: projectFile.extension,
        lastUpdate: projectFile.lastUpdate
      }
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async _uploadImage (constructionSiteId, projectFileId, formData) {
    const options = {
      method: 'post',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/image',
      data: formData,
      headers: authUploadFile()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}

export default new ProjectFileService()
