<template>
  <div class="faultRecordingsContentWrapper">
    <div v-if="isLoading">
      <div class="recordingWrapper">
        <div class="recordingDetails">
          <Skeleton width="70px" height="16px"/>
          <br/>
          <Skeleton width="150px" height="12px"/>
        </div>
        <div class="recordingAudioPlayer">
          <Skeleton width="300px" height="50px"/>
        </div>
      </div>
    </div>
    <div v-if="!isLoading">
      <div v-if="fault.recordings.length === 0">
        <p> Brak nagrań w usterce </p>
      </div>
      <div v-else-if="fault.recordings.length > 0">
        <div v-for="(recording, index) in fault.recordings" :key="recording.id">
          <div class="p-d-flex" :style="recording.UserId === firstUserId ? { 'justify-content': `flex-start`} : { 'justify-content': `flex-end`}">
            <FaultContentRecordingTile :fault="fault" :index="index" :recording="recording"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'
import { computed } from 'vue'
import FaultContentRecordingTile from '@/components/fault/FaultContentRecordingTile'
export default {
  name: 'FaultContentRecordings',
  components: {
    FaultContentRecordingTile,
    Skeleton
  },
  props: {
    fault: {
      required: true
    },
    isLoading: {
      required: false
    }
  },
  setup (props) {
    const firstUserId = computed(() => {
      if (props.fault.recordings.length > 0) {
        return props.fault.recordings[0].UserId
      }
    })

    return { firstUserId }
  }
}
</script>

<style lang="scss" scoped>
.faultRecordingsContentWrapper{
  padding: 3% 18%;

  .recordingWrapper{
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 600px;
    padding: 15px 15px;
    background-color: #ffffff;

    .recordingDetails{
      margin: 15px 0px;
    }

    .recordingAudioPlayer{
      margin: 15px;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }
}
</style>
