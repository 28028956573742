import { defineRule } from 'vee-validate'

defineRule('required', value => {
  if (!value || !value.length) {
    return '* Pole jest wymagane.'
  }

  return true
})

defineRule('min', (value, [min]) => {
  if (value && value.length < min) {
    return `Hasło powinno mieć przynajmniej ${min} znaków`
  }

  return true
})

defineRule('confirmed', (value, [other]) => {
  if (value !== other) {
    return 'Hasła różnią się'
  }

  return true
})

defineRule('email', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return '* Podaj prawidłowy adres e-mail.'
  }

  return true
})

defineRule('text', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-Za-zśćżźłąóę]*$/.test(value)) {
    return '* Pole powinno zawierać same litery.'
  }

  return true
})

defineRule('number', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9]*$/.test(value)) {
    return '* Pole powinno zawierać same cyfry.'
  }

  return true
})

defineRule('postCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{2}-\d{3}$/.test(value)) {
    return '* Pole powinno zawierać kod pocztowy.'
  }

  return true
})

defineRule('textNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9A-Za-zśćżźłąóę]*$/.test(value)) {
    return '* Pole powinno zawierać cyfry lub litery.'
  }

  return true
})

defineRule('phoneNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}-\d{3}-\d{3}$/.test(value)) {
    return '* Podaj prawidłowy numer telefonu.'
  }

  return true
})

defineRule('fiscalCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}-\d{3}-\d{2}-\d{2}$/.test(value)) {
    return '* Podaj prawidłowy NIP.'
  }

  return true
})
