<template>
  <div class="projectCanvasContentWrapper" @mouseup="handleMouseUp" @mousemove.prevent="handleMouseMove">
    <div class="topLabel p-shadow-1">
      <i class="pi pi-replay" @click="rotateImage(true)"/>
      <i class="pi pi-refresh" @click="rotateImage(false)"/>
    </div>

    <div class="box">
      <div
        v-if="imageData.size"
        class="projectImage"
        ref="draggableContainer"
        @mousedown="handleMouseDown"
        @dblclick="handleDoubleClick"
        :style="{
          width: imageData.size.width,
          height: imageData.size.height,
          top: imageData.top,
          left: imageData.left,
          transform: `rotate(${imageData.rotation}deg)`,
          backgroundImage: `url('${imageData.src}')`
        }">
        <div
          v-for="fault in projectFile.faults"
          :key="fault.id"
          class="fault"
          @click="handleNavigationToFault(fault.id, constructionSiteId, projectFile.id)"
          :style="{
            backgroundImage: 'url(' + require('@/assets/markers/' + getRightMarkerByFaultCategory(fault.faultCategory)) + ')',
            left: (fault.markerPositionDx - 32) + 'px',
            top: (fault.markerPositionDy - 64) + 'px',
            transform: `rotate(${-imageData.rotation}deg)`
          }">
          <div class="faultLabel p-shadow-1"> {{ fault.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmationModal
    text="Czy chciałbyś postawić postawić usterkę w tym miejscu ?"
    v-model:visible="placeMarkerModal"
    :noPressed="togglePlaceMarkerModal"
    :yesPressed="handleModalYesPressed"
  />

  <Toast position="bottom-right"/>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { createId, getRightMarkerByFaultCategory } from '@/utils/util'
import Fault from '@/models/fault_model'
import Toast from 'primevue/toast'
import router from '@/router'

export default {
  name: 'ProjectCanvasContent',
  components: {
    ConfirmationModal,
    Toast
  },
  props: {
    constructionSiteId: {
      required: true
    },
    projectFile: {
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const placeMarkerModal = ref(false)
    const draggableContainer = ref(null)
    const imageData = reactive({
      size: null,
      src: null,
      mousePosition: null,
      offset: [0, 0],
      isDown: false,
      left: 0,
      top: 0,
      rotation: 0,
      markerX: 0,
      markerY: 0
    })

    onMounted(async () => {
      const img = new Image()

      img.onload = function () {
        imageData.size = {
          width: this.width + 'px',
          height: this.height + 'px'
        }
        imageData.src = this.src
      }

      img.src = props.projectFile.imageUrl
    })

    const handleMouseDown = (event) => {
      imageData.isDown = true
      imageData.offset = [
        draggableContainer.value.offsetLeft - event.clientX,
        draggableContainer.value.offsetTop - event.clientY
      ]
    }

    const handleMouseUp = () => {
      imageData.isDown = false
    }

    const handleMouseMove = (event) => {
      if (imageData.isDown) {
        imageData.mousePosition = {
          x: event.clientX,
          y: event.clientY
        }
        imageData.left = (imageData.mousePosition.x + imageData.offset[0]) + 'px'
        imageData.top = (imageData.mousePosition.y + imageData.offset[1]) + 'px'
      }
    }

    const rotateImage = (isLeftRotation) => {
      if (isLeftRotation) {
        imageData.rotation = imageData.rotation - 90
        if (imageData.rotation === -360) {
          imageData.rotation = 0
        }
      } else {
        imageData.rotation = imageData.rotation + 90
        if (imageData.rotation === 360) {
          imageData.rotation = 0
        }
      }
    }

    const handleDoubleClick = async (event) => {
      togglePlaceMarkerModal()
      // const clientRect = draggableContainer.value.getBoundingClientRect()
      imageData.markerX = parseFloat(Math.abs(draggableContainer.value.offsetLeft - event.clientX) + '.00001')
      imageData.markerY = parseFloat(Math.abs(draggableContainer.value.offsetTop - event.clientY) + '.00001')
      // console.log(clientRect)
      // const theta = Math.atan2(left, top) * 180 / Math.PI
      //
      // const x = left * Math.cos(theta) - top * Math.sin(theta)
      // const y = left * Math.sin(theta) + top * Math.cos(theta)

      //          |  cos(theta)   -sin(theta) |
      //          |  sin(theta)    cos(theta) |

      if (imageData.rotation === 90 || imageData.rotation === -90) {
        console.log('90 stopni')
      } else if (imageData.rotation === 180 || imageData.rotation === -180) {
        console.log('180 stopni')
      } else if (imageData.rotation === 270 || imageData.rotation === -270) {
        console.log('270 stopni')
      } else if (imageData.rotation === 360 || imageData.rotation === 0 || imageData.rotation === -360) {
        console.log('0 stopni')
      }
    }

    const togglePlaceMarkerModal = () => {
      placeMarkerModal.value = !placeMarkerModal.value
      imageData.markerX = 0
      imageData.markery = 0
    }

    const handleModalYesPressed = async () => {
      const newFault = new Fault({
        id: createId(),
        name: '* Nowa *',
        description: '* Opis usterki *',
        faultCategory: 'FaultCategory.Unassigned',
        faultProgressType: 'FaultProgressType.todo',
        faultPriority: 'FaultPriority.high',
        markerPositionDx: imageData.markerX,
        markerPositionDy: imageData.markerY,
        price: 0,
        isPaid: false,
        ownerAccepted: false,
        workerAccepted: false,
        isArchived: false,
        ProjectFilesId: props.projectFile.id,
        workflows: [
          {
            id: createId(),
            workflow: 'Utworzono nową usterkę.',
            date: new Date().getTime()
          }
        ]
      })

      await store.dispatch('projectFileStore/addFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFile.id,
        fault: newFault
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie usterki',
            detail: 'Pomyślnie dodano usterkę.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Dodawanie usterki',
            detail: error.message,
            life: 3000
          })
        }
      )

      togglePlaceMarkerModal()
    }

    const handleNavigationToFault = (faultId, constructionSiteId, projectFileId) => {
      router.push({
        path: '/fault/' + faultId,
        query: { faultId: faultId, constructionSiteId: constructionSiteId, projectFileId: projectFileId }
      })
    }

    return {
      handleMouseDown,
      handleMouseUp,
      handleMouseMove,
      handleDoubleClick,
      imageData,
      draggableContainer,
      rotateImage,
      placeMarkerModal,
      togglePlaceMarkerModal,
      getRightMarkerByFaultCategory,
      handleModalYesPressed,
      handleNavigationToFault
    }
  }
}
</script>

<style lang="scss" scoped>

.projectCanvasContentWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #b7b7b7;
  position: relative;

  .topLabel {
    width: 300px;
    height: 50px;
    background-color: #007bff;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    z-index: 1;

    i {
      font-size: 25px;
      color: white;
    }

    i:hover {
      color: #d4d4d4;
      cursor: pointer;
    }
  }

  .box {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .projectImage {
      position: absolute;
      background-color: white;
      transition: transform 1s;

      .fault {
        width: 64px;
        height: 64px;
        position: absolute;
        text-align: center;
        cursor: pointer;
        transition: transform 1s;

        .faultLabel {
          position: absolute;
          top: 70px;
          padding: 1px;
          margin-left: -18px;
          width: 100px;
          background-color: white;
          border-radius: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

</style>
