import ConstructionSiteService from '@/services/constructionSite.service'
import ConstructionSite from '@/models/construction_site_model'
import MainService from '@/services/main.service'

const state = {
  constructionSites: null
}

const getters = {}

const actions = {
  fetch ({ commit }) {
    return ConstructionSiteService.fetch().then(
      constructionSites => {
        commit('fetchSuccess', constructionSites)
        return Promise.resolve(constructionSites)
      },
      error => {
        commit('fetchFailure')
        return Promise.reject(error)
      }
    )
  },
  fetchConstructionSiteUsers ({ commit }, constructionSiteId) {
    return ConstructionSiteService.fetchConstructionSiteUsers(constructionSiteId).then(
      constructionSiteAssignedUsers => {
        commit('fetchConstructionSiteUsersSuccess', { constructionSiteAssignedUsers, constructionSiteId })
        return Promise.resolve(constructionSiteAssignedUsers)
      },
      error => {
        commit('fetchConstructionSiteUsersFailure')
        return Promise.reject(error)
      }
    )
  },
  assignUsers ({ commit }, payload) {
    const usersWithEmail = []
    for (let i = 0; i < payload.newUsersList.length; i++) {
      usersWithEmail.push({ email: payload.newUsersList[i].value.email, role: payload.newUsersList[i].value.role })
    }

    return ConstructionSiteService.assignNewUsers(payload.constructionSiteId, usersWithEmail).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  changeUserRole ({ commit }, payload) {
    return ConstructionSiteService.changeUsersRole(payload.constructionSiteId, payload.userId, payload.userWithRole).then(
      res => {
        commit('changeUserRoleSuccess', payload)
        return Promise.resolve(res)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  sendInvitation ({ commit }, newUserEmail) {
    return ConstructionSiteService.sendInvite(newUserEmail).then(
      response => {
        return Promise.resolve(response)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  fetchUserFromDb ({ commit }, email) {
    return ConstructionSiteService.fetchUserFromDb(email).then(
      newUser => {
        return Promise.resolve(newUser)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  add ({ commit }, newConstructionSite) {
    return ConstructionSiteService.add(newConstructionSite).then(
      constructionSite => {
        commit('addConstructionSite', constructionSite)
        return Promise.resolve(constructionSite)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  delete ({ commit }, id) {
    return ConstructionSiteService.delete(id).then(
      constructionSiteId => {
        commit('removeConstructionSite', id)
        return Promise.resolve(constructionSiteId)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  changeName ({ commit }, constructionSite) {
    return ConstructionSiteService.changeName(constructionSite).then(
      res => {
        commit('saveConstructionSite', constructionSite)
        return Promise.resolve(constructionSite)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  uploadImage ({ commit }, payload) {
    return ConstructionSiteService.uploadImage(payload.constructionSite.id, payload.formData).then(
      res => {
        const updatedConstructionSite = new ConstructionSite(JSON.parse(JSON.stringify(payload.constructionSite)))
        updatedConstructionSite.imageUrl = URL.createObjectURL(payload.file)

        commit('saveConstructionSite', updatedConstructionSite)
        return Promise.resolve(updatedConstructionSite)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  fetchProjects ({ commit }, constructionSiteId) {
    return MainService.parseInObjectDetailsScreen(constructionSiteId).then(
      data => {
        commit('fetchProjectsSuccess', { constructionSiteId, data })
        return Promise.resolve(data)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  generateGeneralReport ({ commit }, constructionSiteId) {
    return ConstructionSiteService.generateReport(constructionSiteId).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  generateFilteredReport ({ commit }, payload) {
    return ConstructionSiteService.generateFilterReport(payload.constructionSiteId, payload.requestMap).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        return Promise.reject(error)
      }
    )
  },
  generateFaultReport ({ commit }, payload) {
    return ConstructionSiteService.generateSingleFaultReport(payload.constructionSiteId, payload.projectFileId, payload.faultId).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  addConstructionSite (state, newConstructionSite) {
    state.constructionSites.push(newConstructionSite)
  },
  removeConstructionSite (state, id) {
    let indexOfElementToRemove
    for (let i = 0; i < state.constructionSites.length; i++) {
      if (state.constructionSites[i].id === id) {
        indexOfElementToRemove = i
        break
      }
    }
    state.constructionSites.splice(indexOfElementToRemove, 1)
  },
  saveConstructionSite (state, constructionSite) {
    for (let i = 0; i < state.constructionSites.length; i++) {
      if (state.constructionSites[i].id === constructionSite.id) {
        state.constructionSites[i] = new ConstructionSite(JSON.parse(JSON.stringify(constructionSite)))
        return
      }
    }
  },
  clearNewUsers (state) {
    state.newUser = null
  },
  fetchSuccess (state, fetchConstructionSites) {
    state.constructionSites = fetchConstructionSites
  },
  fetchFailure (state) {
    state.constructionSites = null
  },
  fetchConstructionSiteUsersSuccess (state, data) {
    for (let i = 0; i < state.constructionSites.length; i++) {
      if (state.constructionSites[i].id === data.constructionSiteId) {
        state.constructionSites[i].users = data.constructionSiteAssignedUsers
      }
    }
  },
  fetchConstructionSiteUsersFailure (state) {
    state.constructionSiteAssignedUsers = null
  },
  changeUserRoleSuccess (state, data) {
    for (let i = 0; i < state.constructionSites.length; i++) {
      if (state.constructionSites[i].id === data.constructionSiteId) {
        for (let j = 0; j < state.constructionSites[i].users.length; j++) {
          if (state.constructionSites[i].users[j].id === data.userId) {
            state.constructionSites[i].users[j].role = data.userWithRole.role
          }
        }
      }
    }
  },
  fetchProjectsSuccess (state, payload) {
    for (let i = 0; i < state.constructionSites.length; i++) {
      if (state.constructionSites[i].id === payload.constructionSiteId) {
        for (let j = 0; j < payload.data.projectFiles.length; j++) {
          const projectFile = payload.data.projectFiles[j]
          for (let k = 0; k < projectFile.faults.length; k++) {
            projectFile.faults[k].ProjectFileId = projectFile.id
          }
        }

        state.constructionSites[i].projectFiles = payload.data.projectFiles
        state.constructionSites[i].documents = payload.data.documents
        state.constructionSites[i].users = payload.data.users
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
