<template>
  <div class="navbarWrapper">
    <img @click="handleLogoClick" class="logo" src="../assets/logo.jpg" />

    <div class="routesWrapper">
      <div
        v-for="item in items"
        :key="item.id"
        :class="activeIndex === item.id ? 'box active' : 'box'"
        @click="handleRouteClick(item)">
        <p>{{ item.label }}</p>
      </div>
    </div>

    <div class="p-d-flex p-flex-row p-ai-center">
      <div v-if="!isLoading">
        <span class="accountSpan">{{ user.name + user.surname }}</span>
      </div>
      <Button icon="pi pi-sign-out" iconPos="right" @click="handleLogout" class="p-button-text p-button-text"/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import router from '@/router'
import Button from 'primevue/button'
import { useStore } from 'vuex'

export default {
  name: 'Navbar',
  props: {
    items: {
      type: Array,
      required: true
    },
    screenName: {
      type: String,
      required: true
    }
  },
  components: {
    Button
  },
  setup (props) {
    const activeIndex = ref(null)
    const store = useStore()
    const isLoading = ref(true)
    const user = computed(() => store.state.authStore.userDetails)

    onMounted(() => {
      store.dispatch('authStore/fetchUserDetails').then(
        () => {
          isLoading.value = false
        }
      )

      if (props.screenName === 'constructionSite') {
        activeIndex.value = 1
      } else if (props.screenName === 'projectFile') {
        if (router.currentRoute.value.fullPath.endsWith('faults')) {
          activeIndex.value = 2
        } else if (router.currentRoute.value.fullPath.endsWith('documents')) {
          activeIndex.value = 3
        } else {
          activeIndex.value = 1
        }
      } else if (props.screenName === 'fault') {
        if (router.currentRoute.value.fullPath.endsWith('photos')) {
          activeIndex.value = 2
        } else if (router.currentRoute.value.fullPath.endsWith('recordings')) {
          activeIndex.value = 3
        } else if (router.currentRoute.value.fullPath.endsWith('messages')) {
          activeIndex.value = 4
        } else {
          activeIndex.value = 1
        }
      }
    })

    const handleRouteClick = async (item) => {
      activeIndex.value = item.id
      if (props.screenName === 'fault') {
        await router.push({ path: item.to, query: item.query })
      } else {
        await router.push(item.to)
      }
    }

    const handleLogout = async () => {
      await store.dispatch('authStore/logout')
      await router.push('/')
    }

    const handleLogoClick = () => {
      router.push('/')
    }

    return { activeIndex, handleRouteClick, handleLogoClick, handleLogout, user, isLoading }
  }
}
</script>

<style lang="scss" scoped>
.navbarWrapper {
  width: 100vw;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  position: sticky;
  top: 0;
  z-index: 1000;
  -webkit-box-shadow: 0 1px 10px -7px #000000;
  box-shadow: 0 1px 10px -7px #000000;

  .logo {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }

  .routesWrapper {
    display: flex;
    align-items: center;

    .box {
      height: 70px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 25px;

      p {
        font-weight: 600;
      }
    }
  }

  .active {
    transition: 0.5s;
    box-shadow: 0px -3px 0px #007bff inset;
  }

  .accountSpan {
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
