import SecureLS from 'secure-ls'

export default function authUploadFile () {
  const ls = new SecureLS({ isCompression: false })
  const user = ls.get('user')

  if (user) {
    return { Authorization: 'Bearer ' + user, accept: '*/*', 'Content-Type': 'multipart/form-data' }
  } else {
    return {}
  }
}
