<template>
  <div class="constructionSiteCardWrapper p-shadow-1">
    <router-link
      :to="{ path: '/construction-site/'+ constructionSite.id}">
      <div
        class="image"
        :style="constructionSite.imageUrl !== undefined ?
    { backgroundImage: `url('${constructionSite.imageUrl}')`}
    : { backgroundImage: 'url(' + require('@/assets/placeholder.jpg') + ')'}">
        <div class="changeImage" @click.prevent="toggleAddConstructionSitePhoto">
          <i class="pi pi-images"/>
        </div>
      </div>
    </router-link>

    <div class="details">
      <h3>{{ constructionSite.name }}</h3>
      <span>{{ translateUserRole(constructionSite.role) }}</span>
      <div class="icons">
        <i class="pi pi-pencil" @click.prevent="toggleChangeConstructionSiteName"></i>
        <i class="pi pi-user-plus" @click.prevent="toggleAssignUsersModal"/>
        <i class="pi pi-file" @click.prevent="toggleGenerateReportModal"/>
        <i class="pi pi-trash" @click.prevent="toggleRemoveConstructionSiteModal"/>
      </div>
    </div>
  </div>

  <Dialog header="Dodawanie zdjęcia obiektu" v-model:visible="addPhotoModal" :modal="true">
    <FileUpload
      name="demo[]"
      :multiple="false"
      :fileLimit="1"
      accept="image/*"
      chooseLabel="Wybierz"
      uploadLabel="Wyślij"
      cancelLabel="Usuń wybrane"
      :customUpload="true"
      @uploader="uploadImage">
      <template #empty>
        <p>Przeciagnij i upuść plik, aby załadować.</p>
      </template>
    </FileUpload>
  </Dialog>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunać ten obiekt?"
    v-model:visible="removeConstructionSiteModal"
    :noPressed="toggleRemoveConstructionSiteModal"
    :yesPressed="submitRemoveConstructionSiteModal"
  />

  <BasicFormModal
    v-model:modelValue="changeConstructionSiteNameValue"
    v-model:visible="changeConstructionSiteNameModal"
    :submit="submitChangeConstructionSiteNameModal"
    :toggle="toggleChangeConstructionSiteName"
    header="Zmiana nazwy obiektu"
    placeholder="Nazwa obiektu"
  />

  <GenerateReportModal
    v-model:visible="changeGenerateReportModal"
    :constructionSite="constructionSite"
    :toggle="toggleGenerateReportModal"
    :isVisible="changeGenerateReportModal"
    :sendGeneralReport="submitGenerateGeneralReportModal"
    :sendFilteredReport="submitGenerateFilteredReportModal"
    :sendFaultReport="submitGenerateFaultReportModal"
  />

  <AssignUsersModal
    v-model:visible="changeAssignUsersModal"
    :isOwner="isOwner"
    :constructionSite="constructionSite"
    :toggle="toggleAssignUsersModal"
  />

  <Toast position="bottom-right" />
</template>

<script>
import { clearFileName, createId, sleepOneSec, parseDropdownMapToList, translateUserRole } from '@/utils/util'
import { ref, onMounted, provide, computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import BasicFormModal from '@/components/modals/BasicFormModal'
import router from '@/router'
import AssignUsersModal from '@/components/modals/AssignUsersModal'
import GenerateReportModal from '@/components/modals/GenerateReportModal'

export default {
  name: 'ConstructionSiteCard',
  components: {
    AssignUsersModal,
    GenerateReportModal,
    BasicFormModal,
    Dialog,
    FileUpload,
    ConfirmationModal,
    Toast
  },
  props: {
    constructionSite: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const isLoading = ref(false)
    const addPhotoModal = ref(false)
    const isOwner = computed(() => {
      if (props.constructionSite.role === 'Role.Owner') {
        return true
      } else {
        return false
      }
    })
    const removeConstructionSiteModal = ref(false)
    const changeConstructionSiteNameModal = ref(false)
    const changeConstructionSiteNameValue = ref(props.constructionSite.name)
    const changeAssignUsersModal = ref(false)
    const filteredReportValue = ref()
    const changeGenerateReportModal = ref(false)

    const toggleAddConstructionSitePhoto = () => {
      addPhotoModal.value = !addPhotoModal.value
    }

    const toggleRemoveConstructionSiteModal = () => {
      removeConstructionSiteModal.value = !removeConstructionSiteModal.value
    }

    const toggleChangeConstructionSiteName = () => {
      changeConstructionSiteNameModal.value = !changeConstructionSiteNameModal.value
    }

    const toggleAssignUsersModal = () => {
      changeAssignUsersModal.value = !changeAssignUsersModal.value
    }

    const toggleGenerateReportModal = () => {
      changeGenerateReportModal.value = !changeGenerateReportModal.value
    }

    const submitRemoveConstructionSiteModal = () => {
      toggleRemoveConstructionSiteModal()
      store.dispatch('constructionSiteStore/delete', props.constructionSite.id).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Usuwanie obiektu',
            detail: 'Pomyślnie usunięto obiekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Usuwanie obiektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitChangeConstructionSiteNameModal = () => {
      toggleChangeConstructionSiteName()

      const updatedConstructionSite = JSON.parse(JSON.stringify(props.constructionSite))
      updatedConstructionSite.name = changeConstructionSiteNameValue.value

      store.dispatch('constructionSiteStore/changeName', updatedConstructionSite).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Zmiana nazwy obiektu',
            detail: 'Nazwa obiektu została zaaktualizowana.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Zmiana nazwy obiektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const changeUserRole = async (userId, userWithRole) => {
      await store.dispatch('constructionSiteStore/changeUserRole', {
        constructionSiteId: props.constructionSite.id,
        userId: userId,
        userWithRole: userWithRole
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Zmiania roli użytkownika obiektu',
            detail: 'Pomyślnie zmieniono rolę użytkownika obiektu.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Zmiania roli użytkownika obiektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }
    provide('changeUserRole', changeUserRole)

    const submitGenerateGeneralReportModal = () => {
      toggleGenerateReportModal()
      store.dispatch('constructionSiteStore/generateGeneralReport', props.constructionSite.id).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Generowanie raportu',
            detail: 'Raport obiektu został wysłany na adres email',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Generowanie raportu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitGenerateFilteredReportModal = (selectedProjects, selectedCategories, selectedStatuses, selectedPriorities, selectedDate) => {
      toggleGenerateReportModal()

      const requestMap = {
        locale: 'pl',
        projectids: selectedProjects != null ? parseDropdownMapToList(selectedProjects) : [],
        categories: selectedCategories != null ? parseDropdownMapToList(selectedCategories) : [],
        priorities: selectedPriorities != null ? parseDropdownMapToList(selectedPriorities) : [],
        progresses: selectedStatuses != null ? parseDropdownMapToList(selectedStatuses) : [],
        lastUpdate: Date.parse(selectedDate)
      }

      store.dispatch('constructionSiteStore/generateFilteredReport', { constructionSiteId: props.constructionSite.id, requestMap: requestMap }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Generowanie raportu',
            detail: 'Raport obiektu został wysłany na adres email',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Generowanie raportu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitGenerateFaultReportModal = (selectedFault) => {
      toggleGenerateReportModal()

      store.dispatch('constructionSiteStore/generateFaultReport', { constructionSiteId: props.constructionSite.id, projectFileId: selectedFault.ProjectFileId, faultId: selectedFault.id }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Generowanie raportu',
            detail: 'Raport usterki został wysłany na adres email',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Generowanie raportu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitAssignUsers = async () => {
      toggleAssignUsersModal()

      await store.dispatch('constructionSiteStore/saveNewUsers').then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Przypisano użytkowników',
            detail: 'Pomyślnie dodano użytkowników do obiektu.',
            life: 3000
          })
        }
      )
    }

    const uploadImage = async (event) => {
      const file = event.files[0]
      console.log(file)

      const formData = new FormData()
      formData.append('file', file)
      formData.append('id', createId())
      formData.append('fileName', clearFileName(file.name))

      toggleAddConstructionSitePhoto()

      await store.dispatch('constructionSiteStore/uploadImage', {
        constructionSite: props.constructionSite,
        formData: formData,
        file: file
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie zdjęcia obiektu',
            detail: 'Pomyślnie dodano zdjęcie obiektu.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Dodawanie zdjęcia obiektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const assignNewUser = async (newUsersList) => {
      await store.dispatch('constructionSiteStore/assignUsers', {
        constructionSiteId: props.constructionSite.id,
        newUsersList: newUsersList
      }).then(
        async () => {
          await fetchConstructionSiteUsers()
          updateToast('success', 'Przypisywanie użytkownika', 'Przypisano nowego użytkownika do obiektu.')
        },
        async (userResponse) => {
          if (userResponse.status === 401) {
            await updateToast('warn', 'Wystąpił błąd', userResponse.message)
            await router.push('/')
          } else if (userResponse.status === 200) {
            await updateToast('success', 'Przypisywanie użytkownika', 'Przypisano nowego użytkownika do obiektu.')
          } else if (userResponse.status === 400) {
            await updateToast('warn', 'Wystąpił błąd', userResponse.message)
          } else {
            await updateToast('warn', 'Wystąpił błąd', userResponse.message)
          }
        }
      )
    }

    provide('assignNewUser', assignNewUser)

    const inviteNewUser = async (newUserEmail) => {
      await store.dispatch('constructionSiteStore/sendInvitation', newUserEmail).then(
        async (response) => {
          if (response.status === 200) {
            await updateToast('success', 'Przypisywanie użytkownika', 'Wysłano zaproszenie do użytkownika.')
          } else {
            await updateToast('warn', 'Wystąpił błąd', response.message)
          }
        }
      )
    }

    provide('inviteNewUser', inviteNewUser)

    const updateToast = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: 3000
      })
    }

    provide('updateToast', updateToast)

    const fetchConstructionSiteUsers = async () => {
      await store.dispatch('constructionSiteStore/fetchConstructionSiteUsers', props.constructionSite.id).then(
        () => {
          isLoading.value = false
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Wystąpił błąd',
            detail: error,
            life: 3000
          })
        }
      )
    }

    onMounted(async () => {
      isLoading.value = true
      await fetchConstructionSiteUsers()
      await sleepOneSec()
    })

    return {
      translateUserRole,
      addPhotoModal,
      toggleAddConstructionSitePhoto,
      removeConstructionSiteModal,
      toggleRemoveConstructionSiteModal,
      submitRemoveConstructionSiteModal,
      changeConstructionSiteNameModal,
      changeConstructionSiteNameValue,
      toggleChangeConstructionSiteName,
      submitChangeConstructionSiteNameModal,
      changeGenerateReportModal,
      toggleGenerateReportModal,
      submitGenerateGeneralReportModal,
      filteredReportValue,
      submitGenerateFilteredReportModal,
      submitGenerateFaultReportModal,
      uploadImage,
      changeAssignUsersModal,
      toggleAssignUsersModal,
      submitAssignUsers,
      isLoading,
      isOwner,
      updateToast
    }
  }
}
</script>

<style lang="scss" scoped>
.constructionSiteCardWrapper {
  height: 320px;
  width: 100%;
  background: white;
  border-radius: 25px;

  .image {
    position: relative;
    height: 200px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-topright: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;

    .changeImage {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 35px;
      width: 35px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      z-index: 999;

      i {
        color: black;
        font-size: 20px;
      }
    }
  }

  .details {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icons {
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3.5em;

      i {
        font-size: 20px;
        cursor: pointer;
      }

      @media screen and (min-width:1200px) {
        padding: 0 5em;
      }
    }
  }
}

</style>
