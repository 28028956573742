<template>
  <div class="box p-shadow-1">
    <div v-for="item of items" :key="item.key" class="p-field-radiobutton">
      <RadioButton :id="item.key" name="category" :value="item" v-model="localValue" />
      <label :for="item.key">{{item.name}}</label>
    </div>
  </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton'
import { computed } from 'vue'

export default {
  name: 'CheckboxList',
  props: {
    title: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Object,
      required: true
    }
  },
  components: {
    RadioButton
  },
  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    return {
      localValue
    }
  }
}
</script>

<style lang="scss" scoped>

.box {
  width: 65%;
  padding: 25px 25px 9px 25px;
  background-color: white;
}
</style>
