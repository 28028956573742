<template>
    <Dialog header="Przypisywanie użytkowników" v-model:visible="modalVisible" :modal="true">
      <Form @submit="submitAddNewUser">
        <div v-if="isOwner" class="p-d-flex p-flex-row p-jc-around newUserRow">
          <LabeledInput
            name="assign_user_email_input"
            rules="required|email"
            type="text"
            v-model:modelValue="newUserValue"
            placeholder="Wprowadź adres email"
          />
          <Button class="p-button-raised " label="Dodaj" type="submit"/>
        </div>
      </Form>
      <div v-if="isLoading" class="p-d-flex p-flex-column assignedListWrapper">
        <SkeletonCard />
      </div>
      <div v-if="!isLoading" class="p-d-flex p-flex-column assignedListWrapper">
        <div class="divider div-transparent"/>
        <span class="p-d-flex p-flex-row p-ai-center p-jc-center p-input-icon-right">
          <i class="pi pi-search" />
          <InputText type="text" v-model="searchValue" placeholder="Wyszukaj adres email, firmę lub stanowisko" :disabled="isLoading"/>
        </span>

        <div v-for="singleUser in users" :key="singleUser.key">
          <AssignUsersModalSingleUserRow :singleUser="singleUser" :isOwner="isOwner"/>
        </div>
      </div>
      <div class="modalButtonSection">
        <Button @click="toggle" label="Zamknij" class="p-button-outlined"/>
      </div>
    </Dialog>
</template>

<script>
import { ref, computed, inject } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import router from '@/router'
import { useStore } from 'vuex'
import AssignUsersModalSingleUserRow from '@/components/modals/AssignUsersModalSingleUser'
import SkeletonCard from '@/components/SkeletonCard'

export default {
  name: 'AssignUsersModal',
  components: {
    SkeletonCard,
    AssignUsersModalSingleUserRow,
    Dialog,
    Button,
    LabeledInput,
    InputText,
    Form
  },
  props: {
    toggle: {
      type: Function,
      required: true
    },
    constructionSite: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const newUserValue = ref('')
    const searchValue = ref('')
    const newUserList = []
    const loading = ref(false)
    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })
    const users = computed(() => props.constructionSite.users.filter(user => {
      return user.email?.toLowerCase().includes(searchValue?.value.toLowerCase()) ||
      user.company?.toLowerCase().includes(searchValue?.value.toLowerCase()) ||
      user.department?.toLowerCase().includes(searchValue?.value.toLowerCase()) ||
      user.position?.toLowerCase().includes(searchValue?.value.toLowerCase())
    }))

    const updateToast = inject('updateToast')
    const assignNewUser = inject('assignNewUser')
    const inviteNewUser = inject('inviteNewUser')

    const submitAddNewUser = async () => {
      loading.value = true
      await store.dispatch('constructionSiteStore/fetchUserFromDb', newUserValue.value).then(
        async (response) => {
          if (response.status === 401) {
            await updateToast('warn', 'Wystąpił błąd', response.message)
            await router.push('/')
          } else if (response.status === 200) {
            const newUsersList = []
            const newUser = ref(response.data)
            newUser.value.role = 'Role.Worker'
            newUsersList.push(newUser)

            if (newUser.value.email !== undefined && newUser.value !== null) {
              const contains = ref(false)
              for (let i = 0; i < users.value.length; i++) {
                if (users.value[i].email === newUser.value.email) {
                  contains.value = true
                }
              }
              if (contains.value === false) {
                await assignNewUser(newUsersList)
              } else {
                await updateToast('warn', 'Wystąpił błąd', 'Użytkownik jest już przypisany do obiektu.')
              }
            }
            newUserValue.value = ''
          } else if (response.status === 204) {
            await inviteNewUser(newUserValue.value)
            newUserValue.value = ''
          } else {
            await updateToast('warn', 'Wystąpił błąd', response.message)
            newUserValue.value = ''
          }
        }
      )

      loading.value = false
    }

    return { users, newUserValue, modalVisible, submitAddNewUser, newUserList, loading, searchValue }
  }
}
</script>

<style lang="scss" scoped>
.newUserRow{
  align-items: center;
  padding: 0 0 10px 0;

  .field{
    width: 250px;
  }

  Button{
    height: 35px;
    width: 100px;
  }
}
.divider {
  position: relative;
  margin: 25px 0;
  height: 2px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(200, 200, 200), transparent);
}
.assignedListWrapper{
  width: 500px;
  padding-bottom: 100px;
}
.p-input-icon-right{
  width: auto;
}
.modalButtonSection {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  Button:nth-child(2){
    margin-left: 10px;
  }
}
</style>
