import axios from 'axios'
import authHeader from '@/services/auth_header'
import ProjectFiles from '@/models/project_files_model'
import Documents from '@/models/documents_model'
import User from '@/models/user_model'
import { handleRequestError } from '@/utils/util'
import authOctetHeader from '@/services/auth_octet_header'
import Photo from '@/models/photo_model'
import Recording from '@/models/recording_model'
import Workflow from '@/models/workflow_model'
import Fault from '@/models/fault_model'

const API_URL = 'http://83.12.156.122:8400/construction-sites/'

class MainService {
  async parseInObjectDetailsScreen (id) {
    const options = {
      method: 'get',
      url: API_URL + id + '/projects',
      headers: authHeader()
    }

    const response = await axios(options).then(async (response) => {
      const projectFileList = []
      const documentList = []
      const userList = []

      for (const rawProjectFile of response.data.projectFiles) {
        projectFileList.push(new ProjectFiles(rawProjectFile))
      }

      for (const currentProjectFile of projectFileList) {
        const imageData = await this._getProjectImage(id, currentProjectFile.id)

        if (currentProjectFile.extension === '.svg') {
          const newBlob = new Blob([imageData.data], {
            type: 'image/svg+xml;charset=utf-8'
          })
          currentProjectFile.imageUrl = URL.createObjectURL(newBlob)
        } else {
          currentProjectFile.imageUrl = URL.createObjectURL(imageData.data)
        }
      }

      for (const currentProjectFile of projectFileList) {
        for (const currentFault of currentProjectFile.faults) {
          currentFault.ProjectFileId = currentProjectFile.id
        }
      }

      for (const rawDocument of response.data.documents) {
        documentList.push(new Documents(rawDocument))
      }

      for (const user of response.data.users) {
        userList.push(new User(user))
      }

      return {
        projectFiles: projectFileList,
        documents: documentList,
        users: userList
      }
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async parseInFaultDetailsScreen (constructionSiteId, projectFaultId, faultId) {
    const options = {
      method: 'get',
      url: API_URL + constructionSiteId + '/projects/' + projectFaultId + '/faults/' + faultId,
      headers: authHeader()
    }

    const response = await axios(options).then(async (response) => {
      const photosList = []
      const recordingsList = []
      const workflowsList = []
      const usersList = []

      for (const rawPhoto of response.data.photos) {
        photosList.push(new Photo(rawPhoto))
      }

      for (const currentPhoto of photosList) {
        const imageData = await this.getFaultImage(constructionSiteId, projectFaultId, faultId, currentPhoto.id)

        currentPhoto.imageUrl = URL.createObjectURL(imageData.data)
      }

      for (const rawRecording of response.data.recordings) {
        recordingsList.push(new Recording(rawRecording))
      }

      for (const currentRecording of recordingsList) {
        const audioData = await this.getFaultRecording(constructionSiteId, projectFaultId, faultId, currentRecording.id)

        currentRecording.recordingUrl = URL.createObjectURL(audioData.data)
      }

      for (const rawWorkflow of response.data.workflows) {
        workflowsList.push(new Workflow(rawWorkflow))
      }

      for (const rawUser of response.data.users) {
        usersList.push(new User(rawUser))
      }

      return new Fault({
        id: response.data.id,
        name: response.data.name,
        description: response.data.description,
        faultProgressType: response.data.faultProgressType,
        faultPriority: response.data.faultPriority,
        faultCategory: response.data.faultCategory,
        markerPositionDx: response.data.markerPositionDx,
        markerPositionDy: response.data.markerPositionDy,
        isArchived: response.data.isArchived,
        isPaid: response.data.isPaid,
        price: response.data.price,
        ownerAccepted: response.data.ownerAccepted,
        workerAccepted: response.data.workerAccepted,
        photos: photosList,
        recordings: recordingsList,
        users: usersList,
        workflows: workflowsList,
        UserId: response.data.UserId
      })
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async _getProjectImage (constructionSiteId, projectFileId) {
    const options = {
      method: 'get',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/image',
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async getFaultImage (constructionSiteId, projectFileId, faultId, imageId) {
    const options = {
      method: 'get',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images/' + imageId,
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async getFaultRecording (constructionSiteId, projectFileId, faultId, recordingId) {
    const options = {
      method: 'get',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/recordings/' + recordingId,
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}

export default new MainService()
