import axios from 'axios'
import AuthService from '@/services/auth.service'
import ConstructionSite from '@/models/construction_site_model'
import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { handleRequestError } from '@/utils/util'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'
import User from '@/models/user_model'

const API_URL = 'http://83.12.156.122:8400'

class ConstructionSiteService {
  async fetch () {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites',
      headers: authHeader()
    }

    const response = await axios(options).then(async (response) => {
      const constructionSiteList = []
      for (const rawConstructionSite of response.data) {
        constructionSiteList.push(new ConstructionSite(rawConstructionSite))
      }

      for (const currentConstructionSite of constructionSiteList) {
        if (currentConstructionSite.imageId !== undefined) {
          const imageData = await this._getImage(currentConstructionSite.id)
          currentConstructionSite.imageUrl = URL.createObjectURL(imageData.data)
        }
      }

      return constructionSiteList
    }).catch(async (error) => {
      if (error.response) {
        await AuthService.logout()

        const rejectError = 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText
        return Promise.reject(rejectError)
      }
    })

    return Promise.resolve(response)
  }

  async fetchConstructionSiteUsers (id) {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites/' + id + '/users',
      headers: authHeader()
    }

    const response = await axios(options).then(response => {
      const usersList = []
      for (const assignedUser of response.data) {
        usersList.push(new User(assignedUser))
      }
      return usersList
    }).catch(async (error) => {
      if (error.response) {
        await AuthService.logout()

        const rejectError = 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText
        return Promise.reject(rejectError)
      }
    })

    return Promise.resolve(response)
  }

  async fetchUserFromDb (email) {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites/users?email=' + email,
      headers: authHeader()
    }

    const response = await axios(options).then(async response => {
      return response
    }).catch(async (error) => {
      if (error.response) {
        await AuthService.logout()

        const rejectError = 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText
        return Promise.reject(rejectError)
      }
    })

    return Promise.resolve(response)
  }

  async changeUsersRole (constructionSiteId, userId, userWithRole) {
    const options = {
      method: 'put',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/users/' + userId,
      data: {
        email: userWithRole.email,
        role: userWithRole.role
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async assignNewUsers (constructionSiteId, newUserList) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/users',
      data: newUserList,
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(async response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })
    return Promise.resolve(response)
  }

  async sendInvite (newUserEmail) {
    const options = {
      method: 'post',
      url: API_URL + '/invitation?email=' + newUserEmail,
      headers: { accept: '*/*' }
    }

    const response = await axios(options).then(async response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })
    return Promise.resolve(response)
  }

  async add (newConstructionSite) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites',
      data: {
        id: newConstructionSite.id,
        name: newConstructionSite.name,
        lastUpdate: newConstructionSite.lastUpdate
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(response => {
      return newConstructionSite
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async delete (id) {
    const options = {
      method: 'delete',
      url: API_URL + '/construction-sites/' + id,
      headers: authHeader()
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async changeName (constructionSite) {
    const options = {
      method: 'put',
      url: API_URL + '/construction-sites',
      data: {
        id: constructionSite.id,
        name: constructionSite.name,
        lastUpdate: constructionSite.lastUpdate
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async _getImage (constructionSiteId) {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/image',
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async uploadImage (constructionSiteId, formData) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/image',
      data: formData,
      headers: authUploadFile()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async generateReport (constructionSiteId) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/report',
      headers: authHeader(),
      data: { locale: 'pl' }
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async generateFilterReport (constructionSiteId, formData) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/report',
      headers: authContentTypeHeader(),
      data: formData
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async generateSingleFaultReport (constructionSiteId, projectFileId, faultId) {
    const options = {
      method: 'post',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/report',
      headers: authContentTypeHeader(),
      data: { locale: 'pl' }
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}

export default new ConstructionSiteService()
