<template >
  <div class="projectFileContentFaultsWrapper">
    <div v-if="isLoading">
      <div class="p-field p-col-12 p-md-12 skeletonTilesList">
        <div class="custom-skeleton p-p-2">
          <ul class="p-m-0 p-p-0">
            <div v-for="fault in faultsWithCategory" :key="fault.id">
              <li class="p-mb-3 customTile">
                <div class="p-d-flex p-ai-center">
                  <Skeleton shape="circle" size="2rem" class="p-mr-4"></Skeleton>
                  <Skeleton width="40%" class="p-mb-2"></Skeleton>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!isLoading">
      <Accordion>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryUnassigned.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon unassignedIcon"/>
            <h3> Nieprzypisane </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="unassignedFault in faultsWithCategory.faultCategoryUnassigned" :key="unassignedFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="unassignedFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryGeneral.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon generalIcon"/>
            <h3> Ogólnobudowlane </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="generalFault in faultsWithCategory.faultCategoryGeneral" :key="generalFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="generalFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryPlumbingAndCentralHeating.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon plumbingAndCentralHeatingIcon"/>
            <h3> Wodno-kanalizacyjne i centralne ogrzewanie </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="plumbingAndHeatingFault in faultsWithCategory.faultCategoryPlumbingAndCentralHeating" :key="plumbingAndHeatingFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="plumbingAndHeatingFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryElectrical.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon electricalIcon"/>
            <h3> Elektryczne </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="electricalFault in faultsWithCategory.faultCategoryElectrical" :key="electricalFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="electricalFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryFinishing.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon finishingIcon"/>
            <h3> Wykończeniowe </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="finishingFault in faultsWithCategory.faultCategoryFinishing" :key="finishingFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="finishingFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryTelecom.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon telecomIcon"/>
            <h3> Teletechniczne </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="telecomFault in faultsWithCategory.faultCategoryTelecom" :key="telecomFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="telecomFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryVentilationAndAirCon.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon ventilationAndAirConIcon"/>
            <h3> Wentylacyjne i klimatyzacji </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="ventAndAirConFault in faultsWithCategory.faultCategoryVentilationAndAirCon" :key="ventAndAirConFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="ventAndAirConFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultCategoryOther.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon otherIcon"/>
            <h3> Inne </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="otherFault in faultsWithCategory.faultCategoryOther" :key="otherFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="otherFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab>
          <template #header>
            <span class="faultCategoryText"> {{ faultsWithCategory.faultsCategoryArchived.length }}</span>
            <i class="pi pi-circle-on medium faultCategoryIcon archivedIcon"/>
            <h3> Zarchiwizowane </h3>
          </template>
          <div v-if="!loadingNewFaults">
            <div v-for="archivedFault in faultsWithCategory.faultsCategoryArchived" :key="archivedFault.id">
              <FaultsListTileItem :constructionSiteId="constructionSiteId" :fault="archivedFault" :users="users"/>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>

  <Toast position="bottom-right"/>
</template>

<script>
import FaultsListTileItem from '@/components/project_file/ProjectFileContentFaultListTileItem'
import Skeleton from 'primevue/skeleton'
import { provide } from 'vue'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

export default {
  name: 'ProjectFileContentFaults',
  components: {
    FaultsListTileItem,
    Skeleton,
    Accordion,
    AccordionTab,
    Toast
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false
    },
    loadingNewFaults: {
      type: Boolean,
      required: false
    },
    constructionSiteId: {
      type: String,
      required: true
    },
    faultsWithCategory: {
      required: true
    },
    users: {
      required: true
    }
  },
  setup (props) {
    const toast = useToast()

    const updateToast = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: 3000
      })
    }

    provide('updateToast', updateToast)

    // const updateFaultsLists = () => {
    //   loadingNewFaults.value = true
    //   faultsWithCategory.value.faultCategoryUnassigned = props.faults.faultCategoryUnassigned
    //   faultsWithCategory.value.faultCategoryGeneral = props.faults.faultCategoryGeneral
    //   faultsWithCategory.value.faultCategoryPlumbingAndCentralHeating = props.faults.faultCategoryPlumbingAndCentralHeating
    //   faultsWithCategory.value.faultCategoryElectrical = props.faults.faultCategoryElectrical
    //   faultsWithCategory.value.faultCategoryFinishing = props.faults.faultCategoryFinishing
    //   faultsWithCategory.value.faultCategoryTelecom = props.faults.faultCategoryTelecom
    //   faultsWithCategory.value.faultCategoryVentilationAndAirCon = props.faults.faultCategoryVentilationAndAirCon
    //   faultsWithCategory.value.faultCategoryOther = props.faults.faultCategoryOther
    //   faultsWithCategory.value.faultsCategoryArchived = props.faults.faultsCategoryArchived
    //   loadingNewFaults.value = false
    // }
    // onBeforeUpdate(() => updateFaultsLists())

    return {}
  }
}
</script>

<style lang='scss' scoped>
.projectFileContentFaultsWrapper{
  padding: 5% 18%;

  .skeletonTilesList{
    background-color: #ffff;

    li{
      list-style-type: none;
    }

    .customTile{
      padding: 12px 32px;
    }
  }

  h3{
    font-size: 16px;
  }

  .faultCategoryText{
    font-size: 16px;
    position:absolute;
    left:59px;
    color: #ffff
  }

  .faultCategoryIcon{
    font-size: 30px;
    padding: 5px 15px 5px 5px;
  }
  .unassignedIcon{
    color: #BDBDBD;
  }
  .generalIcon{
    color: #9C27B0;
  }
  .plumbingAndCentralHeatingIcon{
    color: #1476D2;
  }
  .electricalIcon{
    color: #FBC02E;
  }
  .finishingIcon{
    color: #4CAF50;
  }
  .telecomIcon{
    color: #FF9800;
  }
  .ventilationAndAirConIcon{
    color: #785548;
  }
  .otherIcon{
    color: #F44235;
  }
  .archivedIcon{
    color: #3D2722;
  }
  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }
}
</style>
