<template>
  <Dialog :header="header" v-model:visible="modalVisible" :modal="true" style="width: 35em">
    <Form @submit="submit">
      <div class="fileSection">
        <FileUpload
          :key="fileInputKey"
          name="newProjectFile[]"
          mode="basic"
          chooseLabel="Wybierz nowy plik"
          :customUpload="true"
          :auto="true"
          @uploader="uploader"
        />

        <p v-if="fileValue">{{ fileValue.name }}</p>
      </div>
      <p>Osługiwane rozszerzenia: .png, .jpg, .svg, .jpeg.</p>
      <LabeledInput
        name="new_project_type_input"
        rules="required"
        type="text"
        v-model:modelValue="formValue1"
        placeholder="Typ projektu"
        style="width: 50%;"
      />
      <LabeledInput
        name="new_project_name_input"
        rules="required"
        type="text"
        v-model:modelValue="formValue2"
        placeholder="Nazwa nowego projektu"
        style="width: 75%;"
      />
      <div class="modalButtonSection">
        <Button @click="toggle" label="Zamknij" class="p-button-outlined"/>
        <Button label="Dodaj projekt" type="submit"/>
      </div>
    </Form>
  </Dialog>
</template>

<script>
import { computed, onUnmounted, ref } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import LabeledInput from '@/components/LabeledInput'
import FileUpload from 'primevue/fileupload'
import { Form } from 'vee-validate'
import { createId } from '@/utils/util'

export default {
  name: 'AddNewProjectModal',
  components: {
    Dialog,
    Button,
    LabeledInput,
    Form,
    FileUpload
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    modelValue1: {
      type: String,
      required: true
    },
    modelValue2: {
      type: String,
      required: true
    },
    file: {
      required: true
    },
    toggle: {
      type: Function,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    submit: {
      type: Function,
      required: true
    }
  },
  setup (props, { emit }) {
    const fileInputKey = ref(createId())

    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    const formValue1 = computed({
      get () {
        return props.modelValue1
      },
      set (value) {
        emit('update:modelValue1', value)
      }
    })

    const formValue2 = computed({
      get () {
        return props.modelValue2
      },
      set (value) {
        emit('update:modelValue2', value)
      }
    })

    const fileValue = computed({
      get () {
        return props.file
      },
      set (value) {
        emit('update:file', value)
      }
    })

    const uploader = (event) => {
      fileValue.value = event.files[0]
      fileInputKey.value = createId()
    }

    onUnmounted(() => {
      fileValue.value = null
    })

    return {
      fileInputKey,
      modalVisible,
      formValue1,
      formValue2,
      fileValue,
      uploader
    }
  }
}
</script>

<style lang="scss" scoped>
.modalButtonSection {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  Button:nth-child(2) {
    margin-left: 10px;
  }
}

.fileSection {
  display: flex;
  align-items: center;

  p {
    margin-left: 15px;
  }
}

p {
  margin-top: 10px;
}
</style>
