<template>
  <div id="ck-button">
    <label>
      <input type="checkbox" v-model="localValue">
      <span>{{ text }}</span>
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'CheckboxButton',
  props: {
    text: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    return {
      localValue
    }
  }
}
</script>

<style lang="scss" scoped>
#ck-button {
  background-color:#EFEFEF;
  border-radius:4px;
  border:1px solid #D0D0D0;
  float:left;
}

#ck-button {
  background-color:#EFEFEF;
  border-radius:4px;
  border:1px solid #D0D0D0;
  overflow:auto;
  float:left;
}

#ck-button:hover {
  background-color:#EFEFEF;
  border-radius:4px;
  border:1px solid #007bff;
  overflow:auto;
  float:left;
  color:#000;
}

#ck-button label {
  float:left;
}

#ck-button label span {
  text-align:center;
  padding:5px 8px;
  display:block;
}

#ck-button label input {
  position:absolute;
  top:-20px;
}

#ck-button input:checked + span {
  background-color:#007bff;
  color:#fff;
}
</style>
