<template>
  <Navbar :items="navbarItems" screenName="fault"/>
  <router-view v-slot="{ Component, route }">
    <component
      v-if="route.name === 'FaultContent'"
      :is="Component"
      :fault="fault"
      :faultId="faultId"
      :projectFileId="projectFileId"
      :constructionSiteId="constructionSiteId"
      :isLoading="isLoading"
    />
    <component
      v-if="route.name === 'FaultContentPhotos'"
      :is="Component"
      :fault="fault"
      :faultId="faultId"
      :projectFileId="projectFileId"
      :constructionSiteId="constructionSiteId"
      :isLoading="isLoading"
    />
    <component
      v-if="route.name === 'FaultContentRecordings'"
      :is="Component"
      :fault="fault"
      :faultId="faultId"
      :isLoading="isLoading"
    />
    <component
      v-if="route.name === 'FaultContentMessages'"
      :is="Component"
      :fault="fault"
      :faultId="faultId"
      :isLoading="isLoading"
    />
  </router-view>
  <Toast position="bottom-right"/>
</template>

<script>
import Navbar from '@/components/Navbar'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { sleepOneSec } from '@/utils/util'

export default {
  name: 'Fault',
  components: {
    Navbar,
    Toast
  },
  props: {
    faultId: {
      required: true
    },
    constructionSiteId: {
      required: true
    },
    projectFileId: {
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const isLoading = ref(true)
    const fault = computed(() => {
      const projectFile = store.state.projectFileStore.projectFiles?.find(projectFile => projectFile.id === props.projectFileId)
      return projectFile.faults.find(fault => fault.id === props.faultId)
    })
    const navbarItems = [
      {
        id: 1,
        label: 'Usterka',
        to: '/fault/' + props.faultId,
        query: { faultId: props.faultId, constructionSiteId: props.constructionSiteId, projectFileId: props.projectFileId }
      },
      {
        id: 2,
        label: 'Zdjęcia',
        to: '/fault/' + props.faultId + '/photos',
        query: { faultId: props.faultId, constructionSiteId: props.constructionSiteId, projectFileId: props.projectFileId }
      },
      {
        id: 3,
        label: 'Nagrania',
        to: '/fault/' + props.faultId + '/recordings',
        query: { faultId: props.faultId, constructionSiteId: props.constructionSiteId, projectFileId: props.projectFileId }
      },
      {
        id: 4,
        label: 'Wiadomości',
        to: '/fault/' + props.faultId + '/messages',
        query: { faultId: props.faultId, constructionSiteId: props.constructionSiteId, projectFileId: props.projectFileId }
      }
    ]

    onMounted(async () => {
      isLoading.value = true
      await sleepOneSec()
      await store.dispatch('projectFileStore/fetchFault', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFileId,
        faultId: props.faultId
      }).then(
        () => {
          isLoading.value = false
        },
        async (error) => {
          await router.push('/')
          toast.add({
            severity: 'warn',
            summary: 'Wystąpił błąd',
            detail: error,
            life: 3000
          })
        }
      )
    })

    return {
      navbarItems,
      isLoading,
      fault
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
