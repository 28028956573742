<template>
  <div class="registerBox">
    <img :src="require('@/assets/logo.jpg')" alt="logo"/>
    <FormWizard :validation-schema="validationSchema" @submit="onSubmit">
      <FormStep>
        <LabeledInput
          name="first_name_register_input"
          rules="required"
          type="text"
          v-model:modelValue="firstNameRegisterValue"
          placeholder="Imię"
        />
        <LabeledInput
          name="second_name_register_input"
          rules="required"
          type="text"
          v-model:modelValue="secondNameRegisterValue"
          placeholder="Nazwisko"
        />
        <LabeledInput
          name="email_register_input"
          rules="required|email"
          type="text"
          v-model:modelValue="emailRegisterValue"
          placeholder="Adres email"
        />
        <LabeledInput
          name="password_register_input"
          rules="required|min:6"
          type="text"
          v-model:modelValue="passwordRegisterValue"
          placeholder="Hasło"
        />
        <LabeledInput
          name="password_register_confirmation_input"
          rules="required|confirmed:@password_register_input"
          type="text"
          v-model:modelValue="passwordRegisterConfirmationValue"
          placeholder="Potwierdź hasło"
        />
      </FormStep>

      <FormStep>
        <LabeledInput
          name="company_name_register_input"
          rules="required"
          type="text"
          v-model:modelValue="companyNameRegisterValue"
          placeholder="Nazwa firmy"
        />
        <LabeledInput
          name="department_name_register_input"
          rules="required"
          type="text"
          v-model:modelValue="departmentNameRegisterValue"
          placeholder="Dział"
        />
        <LabeledInput
          name="position_name_register_input"
          rules="required"
          type="text"
          v-model:modelValue="positionNameRegisterValue"
          placeholder="Pozycja"
        />
        <div class="cityWrapper">
          <div class="cityNameLabeledInput">
            <LabeledInput
              name="city_name_register_input"
              rules="required"
              type="text"
              v-model:modelValue="cityNameRegisterValue"
              placeholder="Miasto"
            />
          </div>

          <div class="cityNumberLabeledInput">
            <LabeledInput
              name="postal_code_register_input"
              rules="required|postCode"
              type="text"
              v-model:modelValue="postalCodeRegisterValue"
              placeholder="Kod pocztowy"
            />
          </div>
        </div>

        <div class="streetWrapper">
          <div class="streetNameLabeledInput">
            <LabeledInput
              name="street_name_register_input"
              rules="required"
              type="text"
              v-model:modelValue="streetNameRegisterValue"
              placeholder="Ulica"
            />
          </div>

          <div class="streetNumberLabeledInput">
            <LabeledInput
              name="street_number_register_input"
              rules="required"
              type="text"
              v-model:modelValue="streetNumberRegisterValue"
              placeholder="Numer"
            />
          </div>
        </div>
      </FormStep>
    </FormWizard>
  </div>
</template>

<script>
import router from '@/router'
import { createId, sleepOneSec } from '@/utils/util'
import { ref, inject } from 'vue'
import LabeledInput from '@/components/LabeledInput'
import User from '@/models/user_model'
import { useStore } from 'vuex'
import FormWizard from '@/components/FormWizard'
import FormStep from '@/components/FormStep'

export default {
  name: 'RegisterUserContent',
  components: {
    LabeledInput,
    FormWizard,
    FormStep
  },
  setup () {
    const store = useStore()
    const newUserID = createId()
    const firstNameRegisterValue = ref('')
    const secondNameRegisterValue = ref('')
    const emailRegisterValue = ref('')
    const passwordRegisterValue = ref('')
    const passwordRegisterConfirmationValue = ref('')
    const companyNameRegisterValue = ref('')
    const departmentNameRegisterValue = ref('')
    const positionNameRegisterValue = ref('')
    const cityNameRegisterValue = ref('')
    const postalCodeRegisterValue = ref('')
    const streetNameRegisterValue = ref('')
    const streetNumberRegisterValue = ref('')
    const updateToast = inject('updateToast')

    const validationSchema = {
      firstNameRegisterValue: 'required',
      secondNameRegisterValue: 'required',
      emailRegisterValue: 'required|email',
      passwordRegisterValue: 'required|min:6',
      passwordRegisterConfirmationValue: 'required|confirmed:@password_register_input',
      companyNameRegisterValue: 'required',
      departmentNameRegisterValue: 'required',
      positionNameRegisterValue: 'required',
      cityNameRegisterValue: 'required',
      postalCodeRegisterValue: 'required|postCode',
      streetNameRegisterValue: 'required',
      streetNumberRegisterValue: 'required'
    }

    function onSubmit (formData) {
      const newUser = new User({
        id: newUserID,
        name: formData.first_name_register_input,
        surname: formData.second_name_register_input,
        email: formData.email_register_input,
        password: formData.password_register_input,
        company: formData.company_name_register_input,
        department: formData.department_name_register_input,
        position: formData.position_name_register_input,
        city: formData.city_name_register_input,
        street: formData.street_name_register_input,
        addressNumber: formData.street_number_register_input,
        zipCode: formData.postal_code_register_input
      })

      store.dispatch('authStore/register', newUser).then(
        async () => {
          await updateToast('success', 'Zarejestrowano użytkownika', 'Wiadomość z potwierdzeniem została wysłana na adres email')
          await sleepOneSec()
          await router.push('/')
        },
        async error => {
          await updateToast('warn', 'Wystąpił błąd', error.message)
        }
      )
    }

    return {
      firstNameRegisterValue,
      secondNameRegisterValue,
      emailRegisterValue,
      passwordRegisterValue,
      passwordRegisterConfirmationValue,
      companyNameRegisterValue,
      departmentNameRegisterValue,
      positionNameRegisterValue,
      cityNameRegisterValue,
      postalCodeRegisterValue,
      streetNameRegisterValue,
      streetNumberRegisterValue,
      onSubmit,
      validationSchema
    }
  }
}
</script>

<style lang="scss" scoped>

.registerBox {
  width: 500px;
  background-color: white;
  padding: 25px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);

  .cityWrapper {
    display: flex;
    flex-direction: row;

    .cityNameLabeledInput {
      flex: 3;
      padding: 0 0 20px 0;
    }

    .cityNumberLabeledInput {
      flex: 2;
      padding: 0 0 20px 15px;
    }
  }

  .streetWrapper {
    display: flex;
    flex-direction: row;

    .streetNameLabeledInput {
      flex: 3;
      padding: 0 0 20px 0;
    }

    .streetNumberLabeledInput {
      flex: 2;
      padding: 0 0 20px 15px;
    }
  }

  .labeledInputPadding {
    padding: 0 40px;
  }

  img {
    width: 150px;
    align-self: center;
  }
}
</style>
