import axios from 'axios'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { handleRequestError } from '@/utils/util'
import authOctetHeader from '@/services/auth_octet_header'
import authUploadFile from '@/services/auth_upload_file'

const API_URL = 'http://83.12.156.122:8400/construction-sites/'

class FaultService {
  async add (constructionSiteId, projectFileId, fault) {
    const options = {
      method: 'post',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults',
      data: {
        id: fault.id,
        name: fault.name,
        description: fault.description,
        faultCategory: fault.faultCategory,
        faultProgressType: fault.faultProgressType,
        faultPriority: fault.faultPriority,
        markerPositionDx: fault.markerPositionDx,
        markerPositionDy: fault.markerPositionDy,
        price: fault.price,
        isPaid: fault.isPaid,
        ownerAccepted: fault.ownerAccepted,
        workerAccepted: fault.workerAccepted,
        isArchived: fault.isArchived,
        workflows: fault.workflows,
        lastUpdate: fault.lastUpdate
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(async (response) => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async update (constructionSiteId, projectFileId, fault) {
    const options = {
      method: 'put',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults',
      data: {
        id: fault.id,
        name: fault.name,
        description: fault.description,
        faultCategory: fault.faultCategory,
        faultProgressType: fault.faultProgressType,
        faultPriority: fault.faultPriority,
        markerPositionDx: fault.markerPositionDx,
        markerPositionDy: fault.markerPositionDy,
        price: fault.price,
        isPaid: fault.isPaid,
        ownerAccepted: fault.ownerAccepted,
        workerAccepted: fault.workerAccepted,
        isArchived: fault.isArchived,
        workflows: fault.workflows,
        UserId: fault.UserId,
        lastUpdate: fault.lastUpdate
      },
      headers: authContentTypeHeader()
    }

    const response = await axios(options).then(async (response) => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async getImage (constructionSiteId, projectFileId, faultId, imageId) {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites/' + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images/' + imageId,
      responseType: 'blob',
      headers: authOctetHeader()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async uploadImage (constructionSiteId, projectFileId, faultId, formData) {
    const options = {
      method: 'post',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images',
      data: formData,
      headers: authUploadFile()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async removeImage (constructionSiteId, projectFileId, faultId, faultPhotoId) {
    const options = {
      method: 'delete',
      url: API_URL + constructionSiteId + '/projects/' + projectFileId + '/faults/' + faultId + '/images/' + faultPhotoId,
      headers: authUploadFile()
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}

export default new FaultService()
