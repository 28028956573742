<template>
  <div class="divider div-transparent"/>
  <div class="p-d-flex p-flex-row p-jc-between p-ac-center singleUser">
    <div class="p-d-flex p-flex-column p-jc-between p-ac-center">
      <span> {{ singleUser.email }} </span>
      <div class="p-d-flex p-flex-row">
        <h4> {{ singleUser.company }} - {{ singleUser.department }} -</h4>
        <span> {{ singleUser.position }} </span>
      </div>
    </div>
    <Dropdown v-model="selectedRole" :options="roles" v-on:change="changeSelectedUserRole" :disabled="!isOwner" optionLabel="name"></Dropdown>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import Dropdown from 'primevue/dropdown'
import { translateUserRole } from '@/utils/util'

export default {
  name: 'AssignUsersModalSingleUserRow',
  components: {
    Dropdown
  },
  props: {
    singleUser: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const selectedRole = ref({ name: translateUserRole(props.singleUser.role), role: props.singleUser.role })
    const roles = [
      { name: 'Właściciel', role: 'Role.Owner' },
      { name: 'Pracownik', role: 'Role.Worker' }
    ]
    const changeUserRole = inject('changeUserRole')

    const changeSelectedUserRole = async () => {
      await changeUserRole(props.singleUser.id, { email: props.singleUser.email, role: selectedRole.value.role })
    }

    return { selectedRole, roles, changeSelectedUserRole }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  position: relative;
  margin: 25px 0;
  height: 2px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(200, 200, 200), transparent);
}
.singleUser{
  padding: 0 15px 0 15px;
}
</style>
