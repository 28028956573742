import axios from 'axios'
import registerUserContentTypeHeader from '@/services/register_user_content_type_header'
import SecureLS from 'secure-ls'
import authContentTypeHeader from '@/services/auth_content_type_header'

const API_URL = 'http://83.12.156.122:8400'
const ls = new SecureLS({ isCompression: false })

class AuthService {
  login (user) {
    const params = new URLSearchParams()
    params.append('email', user.email.trim())
    params.append('password', user.password.trim())

    return axios.post(API_URL + '/login', params)
      .then(this.handleResponse)
      .then(response => {
        if (response.data) {
          ls.set('user', response.data)
        }

        return response.data
      })
  }

  logout () {
    ls.clear()
  }

  register (user) {
    const options = {
      method: 'post',
      url: API_URL + '/registration',
      data: {
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
        password: user.password,
        company: user.company,
        department: user.department,
        position: user.position,
        city: user.city,
        street: user.street,
        addressNumber: user.addressNumber,
        zipCode: user.zipCode
      },
      headers: registerUserContentTypeHeader()
    }

    return axios(options).then(this.handleResponse).then(response => {
      return response.data
    })
  }

  async fetchDetails () {
    const options = {
      method: 'get',
      url: API_URL + '/construction-sites/users/details',
      headers: authContentTypeHeader()
    }

    return await axios(options).then(this.handleResponse).then(response => {
      return response.data
    })
  }

  handleResponse (response) {
    if (response.status === 401) {
      this.logout()
      location.reload(true)

      const error = response.data && response.data.message
      return Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default new AuthService()
