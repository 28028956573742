import { v1 as uuidv1 } from 'uuid'
import AuthService from '@/services/auth.service'

export function sleepOneSec () {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved')
    }, 1500)
  })
}

export function translateUserRole (userRole) {
  switch (userRole) {
    case 'Role.Owner':
      return 'Właściciel'
    case 'Role.Worker':
      return 'Pracownik'
    default:
      return ''
  }
}

export function getRightMarkerByFaultCategory (faultCategory) {
  switch (faultCategory) {
    case 'FaultCategory.Unassigned':
      return 'location_unassigned.png'
    case 'FaultCategory.Electrical':
      return 'location_electrical.png'
    case 'FaultCategory.PlumbingAndCentralHeating':
      return 'location_plumbingandcentralheating.png'
    case 'FaultCategory.Finishing':
      return 'location_finishing.png'
    case 'FaultCategory.Telecom':
      return 'location_telecom.png'
    case 'FaultCategory.VentilationAndAirCon':
      return 'location_ventilationandaircon.png'
    case 'FaultCategory.General':
      return 'location_general.png'
    case 'FaultCategory.Other':
      return 'location_other.png'
    default:
      return ''
  }
}

export function parseDateSinceEpoch (dateSinceEpoch) {
  const d = new Date(dateSinceEpoch)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let minutes = '' + d.getMinutes()
  const year = d.getFullYear()
  const hour = d.getHours()

  if (month.length < 2) { month = '0' + month }
  if (day.length < 2) { day = '0' + day }
  if (minutes.length < 2) { minutes = '0' + minutes }

  const fullDateWithoutTime = [year, month, day].join('-')
  const dateTime = [hour, minutes].join(':')
  return fullDateWithoutTime + ' ' + dateTime
}

export function parseDropdownMapToList (selectedMap) {
  const selectedItemList = []
  for (let i = 0; i < selectedMap.length; i++) {
    selectedItemList.push(selectedMap[i].code)
  }
  return selectedItemList
}

export function b64toBlob (dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export function resizeImage (base64Str) {
  const img = new Image()
  img.src = base64Str
  const canvas = document.createElement('canvas')
  const MAX_WIDTH = 640
  const MAX_HEIGHT = 480
  let width = img.width
  let height = img.height

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width
      width = MAX_WIDTH
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height
      height = MAX_HEIGHT
    }
  }
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, width, height)
  return canvas.toDataURL()
}

export function createId () {
  const newId = uuidv1()
  return newId.toString().replaceAll('-', '')
}

export function getCurrentTime () {
  return new Date().getTime()
}

export async function handleRequestError (error) {
  let rejectError = ''
  if (error.response.data.message) {
    rejectError = { status: error.response.status, message: error.response.data.message }
  } else {
    rejectError = { status: error.response.status, message: 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText }
  }

  if (error.response.status === 401) {
    await AuthService.logout()
  }

  return Promise.reject(rejectError)
}

export function clearFileName (fileName) {
  return fileName.replace(/[\\[\]']+/g, '').replace(/[\\(\\)']+/g, '').replace(' ', '')
}

export function isOnMobile () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
