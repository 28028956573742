<template>
  <div class="constructionSiteCardWrapper p-shadow-1">
    <div
      v-if="imageData.size"
      class="image"
      @click="goToProjectCanvas"
      :style="projectFile.imageUrl !== undefined ?
        { backgroundImage: `url('${imageData.src}')`}
        : { backgroundImage: 'url(' + require('@/assets/placeholder.jpg') + ')'}"
    />

    <div class="details">
      <h3>{{ projectFile.name }}</h3>
      <span>{{ projectFile.type }}</span>
      <div class="icons">
        <i class="pi pi-pencil" @click.prevent="toggleChangeNameProjectFileModal"/>
        <i class="pi pi-key" @click.prevent="toggleChangeTypeProjectFileModal"/>
        <i class="pi pi-trash" @click.prevent="toggleRemoveProjectFileModal"/>
      </div>
    </div>
  </div>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunać ten projekt?"
    v-model:visible="removeProjectFileModal"
    :noPressed="toggleRemoveProjectFileModal"
    :yesPressed="submitRemoveProjectFileModal"
  />

  <BasicFormModal
    v-model:modelValue="changeNameProjectFileModalValue"
    v-model:visible="changeNameProjectFileModal"
    :submit="submitChangeNameProjectFile"
    :toggle="toggleChangeNameProjectFileModal"
    header="Zmiana nazwy projektu"
    placeholder="Nazwa projektu"
  />

  <BasicFormModal
    v-model:modelValue="changeTypeProjectFileModalValue"
    v-model:visible="changeTypeProjectFileModal"
    :submit="submitChangeTypeProjectFile"
    :toggle="toggleChangeTypeProjectFileModal"
    header="Zmiana typu projektu"
    placeholder="Typ projektu"
  />

  <Toast position="bottom-right"/>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import router from '@/router'
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex'

import Toast from 'primevue/toast'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import BasicFormModal from '@/components/modals/BasicFormModal'

export default {
  name: 'ProjectFileContentCard',
  components: {
    Toast,
    ConfirmationModal,
    BasicFormModal
  },
  props: {
    projectFile: {
      type: Object,
      required: true
    },
    constructionSiteId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const toast = useToast()
    const store = useStore()
    const imageData = reactive({
      size: null,
      src: null
    })

    onMounted(() => {
      const img = new Image()
      img.onload = function () {
        imageData.size = {
          width: this.width + 'px',
          height: this.height + 'px'
        }
        imageData.src = this.src
      }

      img.src = props.projectFile.imageUrl
    })

    const removeProjectFileModal = ref(false)
    const changeNameProjectFileModal = ref(false)
    const changeTypeProjectFileModal = ref(false)

    const changeNameProjectFileModalValue = ref(props.projectFile.name)
    const changeTypeProjectFileModalValue = ref(props.projectFile.type)

    const toggleRemoveProjectFileModal = () => {
      removeProjectFileModal.value = !removeProjectFileModal.value
    }

    const toggleChangeNameProjectFileModal = () => {
      changeNameProjectFileModal.value = !changeNameProjectFileModal.value
    }

    const toggleChangeTypeProjectFileModal = () => {
      changeTypeProjectFileModal.value = !changeTypeProjectFileModal.value
    }

    const submitRemoveProjectFileModal = () => {
      toggleRemoveProjectFileModal()
      store.dispatch('projectFileStore/delete', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFile.id
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Usuwanie projektu',
            detail: 'Pomyślnie usunięto projekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Usuwanie projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitChangeNameProjectFile = () => {
      toggleChangeNameProjectFileModal()

      const updatedProjectFile = JSON.parse(JSON.stringify(props.projectFile))
      updatedProjectFile.name = changeNameProjectFileModalValue.value

      store.dispatch('projectFileStore/update', {
        constructionSiteId: props.constructionSiteId,
        projectFile: updatedProjectFile
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Modyfikacja projektu',
            detail: 'Pomyślnie zaaktualizowano nazwę projekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Modyfikacja projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const submitChangeTypeProjectFile = () => {
      toggleChangeTypeProjectFileModal()

      const updatedProjectFile = JSON.parse(JSON.stringify(props.projectFile))
      updatedProjectFile.type = changeTypeProjectFileModalValue.value

      store.dispatch('projectFileStore/update', {
        constructionSiteId: props.constructionSiteId,
        projectFile: updatedProjectFile
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Modyfikacja projektu',
            detail: 'Pomyślnie zaaktualizowano typ projekt.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Modyfikacja projektu',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const goToProjectCanvas = () => {
      router.push('/construction-site/' + props.constructionSiteId + '/project-file/' + props.projectFile.id)
    }

    return {
      removeProjectFileModal,
      toggleRemoveProjectFileModal,
      submitRemoveProjectFileModal,
      changeNameProjectFileModal,
      changeTypeProjectFileModal,
      changeNameProjectFileModalValue,
      changeTypeProjectFileModalValue,
      toggleChangeNameProjectFileModal,
      toggleChangeTypeProjectFileModal,
      submitChangeNameProjectFile,
      submitChangeTypeProjectFile,
      goToProjectCanvas,
      imageData
    }
  }
}
</script>

<style lang="scss" scoped>
.constructionSiteCardWrapper {
  height: 320px;
  width: 100%;
  background: white;
  border-radius: 25px;

  .image {
    position: relative;
    height: 200px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-top-right-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-topright: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .details {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icons {
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3.5em;

      i {
        font-size: 20px;
        cursor: pointer;
      }

      @media screen and (min-width: 1200px) {
        padding: 0 5em;
      }
    }
  }
}
</style>
