<template>
  <div class="faultPhotosContentWrapper">
    <Button @click="toggleFaultPhotoModal" label="Dodaj nowe zdjęcie" icon="pi pi-plus" iconPos="left"
            :disabled="isLoading"/>
    <div v-if="isLoading">
      <div class="faultCardWrapper p-shadow-1">
        <div class="image">
          <Skeleton height="250px"/>
        </div>
      </div>
    </div>
    <div v-if="!isLoading">
      <div v-if="fault.photos.length === 0">
        <p> Brak zdjęć w usterce.</p>
      </div>
      <div v-else-if="fault.photos.length > 0">
        <Galleria :value="fault.photos" v-model:activeIndex="activeIndex" :numVisible="7" containerStyle="max-width: 840px"
                  :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayCustom">
          <template #item="slotProps">
            <img :src="slotProps.item.imageUrl" :alt="slotProps.item.alt"/>
          </template>
          <template #thumbnail="slotProps">
            <img :src="slotProps.item.imageUrl" :alt="slotProps.item.alt" style="display: block;" />
          </template>
        </Galleria>

        <div v-if="fault.photos" class="p-grid photoGrid">
          <div v-for="(image, index) of fault.photos" :key="index">
            <FaultContentCard v-on:imageClick="imageClick" v-on:submitRemoveFaultPhotoModal="submitRemoveFaultPhotoModal" :image="image" :index="index"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog header="Dodawanie zdjęcia usterki" v-model:visible="addPhotoModal" :modal="true">
    <div>
      <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
      />
      <Button :label="addImageText" icon="pi pi-plus" @click.prevent="showFileChooser"/>

      <div class="content">
        <section class="cropper-area">
          <div v-if="imgSrc" class="img-cropper">
            <vue-cropper
              ref="cropper"
              :cropBoxResizable="true"
              :aspect-ratio="4 / 3"
              :src="imgSrc"
              preview=".preview"
            />
          </div>
          <div v-else class="cropperPlaceholder"/>
          <div v-if="imgSrc" class="actions">
            <Button label="Obróć w prawo" icon="pi pi-refresh" @click.prevent="rotate(90)" class="p-button-text"/>
            <Button label="Obróć w lewo" icon="pi pi-replay" @click.prevent="rotate(-90)" class="p-button-text"/>
            <Button label="Przywróć" icon="pi pi-times" @click.prevent="reset" class="p-button-text"/>
            <Button label="Przytnij" icon="pi pi-window-minimize" @click.prevent="cropImage" class="p-button-outlined"/>
          </div>
        </section>
        <section class="preview-area">
          <p>Podgląd</p>
          <div v-if="imgSrc" class="preview" />
          <div v-else class="previewPlaceholder"/>
          <p>Wykadrowane zdjęcie</p>
          <div class="cropped-image">
            <img
              v-if="cropImg"
              :src="cropImg"
              alt="Cropped Image"
            />
            <div v-else class="crop-placeholder" />
          </div>
        </section>
      </div>
    </div>
    <template #footer>
      <div class="footerButtons">
        <Button @click="toggleFaultPhotoModal" label="Zamknij" class="p-button-outlined"/>
        <Button :disabled="!canUploadPhoto" label="Wyślij" icon="pi pi-send" @click.prevent="uploadImage"/>
      </div>
    </template>
  </Dialog>

  <Toast position="bottom-right"/>
</template>

<script>
import Galleria from 'primevue/galleria'
import { ref, computed } from 'vue'
import Skeleton from 'primevue/skeleton'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import router from '@/router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import FaultContentCard from '@/components/fault/FaultContentCard'
import { b64toBlob, clearFileName, createId, resizeImage } from '@/utils/util'

export default {
  name: 'FaultContentPhotos',
  props: {
    fault: {
      required: true
    },
    constructionSiteId: {
      required: true
    },
    projectFileId: {
      required: true
    },
    faultId: {
      required: true
    },
    isLoading: {
      required: false
    }
  },
  methods: {
    imageClick (index) {
      this.activeIndex = index
      this.displayCustom = true
    }
  },
  components: {
    FaultContentCard,
    Galleria,
    Button,
    Skeleton,
    Dialog,
    Toast,
    VueCropper
  },
  setup (props) {
    const store = useStore()
    const toast = useToast()
    const addPhotoModal = ref(false)
    const activeIndex = ref(0)
    const displayCustom = ref(false)
    const cropper = ref('cropper')
    const input = ref('input')
    const imgSrc = ref(null)
    const canUploadPhoto = ref(false)
    const cropImg = ref('')
    const selectedFileName = ref('')
    const data = ref(null)
    const addImageText = computed(() => imgSrc.value ? 'Zmień zdjęcie' : 'Dodaj zdjęcie')

    const cropImage = () => {
      cropImg.value = cropper.value.getCroppedCanvas().toDataURL()
      canUploadPhoto.value = true
    }
    const reset = () => {
      cropper.value.reset()
    }
    const rotate = (deg) => {
      cropper.value.rotate(deg)
    }
    const setImage = (e) => {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        toast.add({
          severity: 'warn',
          summary: 'Dodawanie zdjęcia usterki',
          detail: 'Proszę wybrać zdjęcie usterki.',
          life: 3000
        })
        return
      }
      if (typeof FileReader === 'function') {
        selectedFileName.value = file.name
        const reader = new FileReader()
        reader.onload = (event) => {
          imgSrc.value = event.target.result
          cropper.value.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Dodawanie zdjęcia usterki',
          detail: 'Przepraszamy, przeglądarka nie obsługuje tej funkcji.',
          life: 3000
        })
      }
    }
    const showFileChooser = () => {
      input.value.click()
    }

    const toggleFaultPhotoModal = () => {
      addPhotoModal.value = !addPhotoModal.value
    }

    const submitRemoveFaultPhotoModal = (index, image) => {
      const faultPhotoId = image.id

      store.dispatch('projectFileStore/removeFaultPhoto', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFileId,
        faultId: props.fault.id,
        faultPhotoId: faultPhotoId
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Usuwanie zdjęcia usterki',
            detail: 'Pomyślnie usunięto zdjęcie usterki.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Usuwanie zdjęcia usterki',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    const uploadImage = async () => {
      const resizedImage = resizeImage(cropImg.value)
      const file = b64toBlob(resizedImage)
      const id = createId()
      const fileName = clearFileName(selectedFileName.value)

      const formData = new FormData()
      formData.append('file', file)
      formData.append('id', id)
      formData.append('fileName', fileName)

      toggleFaultPhotoModal()

      await store.dispatch('projectFileStore/uploadFaultPhoto', {
        constructionSiteId: props.constructionSiteId,
        projectFileId: props.projectFileId,
        faultId: props.fault.id,
        file: file,
        id: id,
        formData: formData,
        fileName: fileName,
        fault: props.fault
      }).then(
        () => {
          toast.add({
            severity: 'success',
            summary: 'Dodawanie zdjęcia usterki',
            detail: 'Pomyślnie dodano zdjęcie usterki.',
            life: 3000
          })
        },
        async (error) => {
          if (error.status === 401) {
            await router.push('/')
          }
          toast.add({
            severity: 'warn',
            summary: 'Dodawanie zdjęcia usterki',
            detail: error.message,
            life: 3000
          })
        }
      )
    }

    return {
      activeIndex,
      displayCustom,
      toggleFaultPhotoModal,
      addPhotoModal,
      canUploadPhoto,
      uploadImage,
      submitRemoveFaultPhotoModal,
      cropImage,
      reset,
      rotate,
      setImage,
      showFileChooser,
      cropper,
      input,
      imgSrc,
      cropImg,
      data,
      addImageText
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;

  h2{
    margin: 0;
  }
}

input[type="file"] {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
}
.cropper-area {
  width: 640px;
  margin: 15px 15px 15px 0;
}
.cropperPlaceholder{
  width: 640px;
  height: 480px;
  background: #ccc;
}
.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;

  Button {
    margin: 5px;
  }
}
.preview-area {
  width: 300px;
  justify-content: space-between;

  p:last-of-type {
    margin-top: 1rem;
  }
}
.previewPlaceholder{
  width: 100%;
  height: 200px;
  background: #ccc;
}
.preview {
  width: 100%;
  height: calc(372px * (3 / 4));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}

.faultPhotosContentWrapper{
  padding: 3% 18%;

  .faultCardWrapper {
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-items: center;
    border-radius: 3px;
    background: white;

    .image {
      width: 280px;
      height: 280px;
      cursor: pointer;
    }
  }

  img{
    width: 100%;
    display: block;
  }

  .photoGrid{
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }
}
</style>
