import SecureLS from 'secure-ls'

export default function authOctetHeader () {
  const ls = new SecureLS({ isCompression: false })
  const user = ls.get('user')

  if (user) {
    return { Authorization: 'Bearer ' + user, accept: 'application/octet-stream' }
  } else {
    return {}
  }
}
