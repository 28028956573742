<template>
  <div v-if="isMobile">
    <MobileCard/>
  </div>
  <div v-else>
    <div class="loginWrapper">
      <div class="loginBox">
        <img :src="require('@/assets/logo.jpg')" alt="logo"/>
        <Form @submit="handleLoginSubmit">

          <div class="labeledInputPadding">
            <LabeledInput
              name="email_login_input"
              rules="required|email"
              type="text"
              v-model:modelValue="emailValue"
              placeholder="Adres email"
            />
          </div>
          <div class="labeledInputPadding">
            <LabeledInput
              name="password_login_input"
              rules="required"
              type="password"
              v-model:modelValue="passwordValue"
              placeholder="Hasło"
            />
          </div>

          <div class="buttonSection">
            <Button label="Zaloguj się" type="submit"/>
            <Button @click="handleRegisterClick" label="Zarejestruj się" class="p-button-outlined"/>
          </div>

        </Form>
      </div>
    </div>

    <Toast position="bottom-right"/>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import router from '@/router'
import User from '@/models/user_model'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import Button from 'primevue/button'
import { isOnMobile } from '@/utils/util'
import MobileCard from '@/components/MobileCard'

export default {
  name: 'Login',
  components: {
    Toast,
    Form,
    LabeledInput,
    Button,
    MobileCard
  },
  setup () {
    const store = useStore()
    const emailValue = ref('')
    const passwordValue = ref('')
    const isLoggedIn = computed(() => store.state.authStore.status.loggedIn)
    const loading = ref(false)
    const toast = useToast()
    const isMobile = computed(() => isOnMobile())

    onMounted(() => {
      if (isLoggedIn.value) router.push('/construction-site')
    })

    const handleRegisterClick = () => {
      router.push('/register')
    }

    const handleLoginSubmit = async () => {
      loading.value = true

      if (emailValue.value && passwordValue.value) {
        const newUser = new User({
          email: emailValue.value,
          password: passwordValue.value
        })

        store.dispatch('authStore/login', newUser).then(
          () => {
            router.push('/construction-site')
          },
          error => {
            loading.value = false
            toast.add({
              severity: 'warn',
              summary: 'Wystąpił błąd',
              detail: error.message,
              life: 3000
            })
          }
        )
      }
    }

    return {
      handleLoginSubmit,
      emailValue,
      passwordValue,
      handleRegisterClick,
      isMobile
    }
  }
}
</script>
<style lang="scss" scoped>
.loginWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loginBox {
    width: 400px;
    background-color: white;
    padding: 25px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    -webkit-box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.08);

    .labeledInputPadding {
      padding: 0 20px;
    }

    img {
      width: 150px;
      align-self: center;
    }

    .buttonSection {
      margin-top: 45px;
      display: flex;
      flex-direction: column;

      button:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
